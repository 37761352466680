// export const BASE_URL = "https://api-abl-dev-v1.0.premcoprecast.com/";
export const BASE_URL = "https://api-jbs-denim-v1.0.premcoprecast.com/";
// export const BASE_URL = "https://api-admins-abl-v1.0.premcoprecast.com/";

import { getDeviceName, getBrowserName } from "@/utils/functions";
export const USER_VERSION = {
  userPlatFormToken: "PFT-3",
  userFirebaseToken: "test",
  userVersionNumber: "1.5",
  deviceNameEn: "Unkonwn",
  deviceVersion: getBrowserName() + " " + getDeviceName(),
};

export const DEFAULT_IMG = BASE_URL + "/_DefaultFiles/employee.png";
export const DEFAULT_IMG_NEWS = BASE_URL + "/_DefaultFiles/employee.png";
export const DEFAULT_IMG_BRANCH = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_STORE = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_REVENUE =
  BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_EXPENSE =
  BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const DEFAULT_IMG_CLIENT = BASE_URL + "/_DefaultFiles/defaultBranch.png";
export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};

export const HOME_PAGE = "/";
export const HOME_PAGE_LOGIN = "/employees";
export const ESTABLISHMENT_TOKEN = "EST-1";
export const PAGE_SIZE = 16;
export const IMPOSSIBLE_TOKEN = 0;
export const SERVICE_TYPE_NAME_MIN_LENGTH = 3;
export const SERVICE_TYPE_NAME_MAX_LENGTH = 50;
export const SERVICE_NAME_DESC = 500;
export const SCIENTIFIC_DEGREE_NAME_MIN_LENGTH = 3;
export const SCIENTIFIC_DEGREE_NAME_MAX_LENGTH = 50;
export const SCIENTIFIC_DEGREE_NAME_DESC = 500;
export const GENERAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const GENERAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const GENERAL_SPECIALIATY_NAME_DESC = 500;
export const SPECIAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const SPECIAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const SPECIAL_SPECIALIATY_NAME_DESC = 500;
export const SERVICE_MIN_LENGTH = 3;
export const SERVICE_MAX_LENGTH = 50;
export const SERVICE_DESC = 500;

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 500;
export const TEXT_MAX_LENGTH_SUPER = 1000000;
export const MAX_ROWS_TEXTAREA = 100;

export const NEWS_MEDIA_TYPE_TOKEN_VIDEO = "MET-2";
export const NEWS_MEDIA_TYPE_TOKEN_IMAGE = "MET-1";
export const VIDEO_EXTENSIONS = [
  "mov",
  "avi",
  "wmv",
  "mp4",
  "m4p",
  "m4v",
  "ogg",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "3gp",
  "flv",
];
